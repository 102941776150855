import Model from './model.js';

class Product extends Model {
  id = 0;
  isActive = true;
  salesCode = null;
  productType = null;
  customsPrice = 0;
  price = 0;
  stock = 0;
  vatPercentage = null;
  articleNumber = '';
  name = '';
  ean = '';

  mapForRequest() {
    return {
      isActive: this.isActive,
      salesCodeId: this.salesCode?.id || undefined,
      salesCode: this.salesCode?.id ? undefined : this.salesCode,
      customsPrice: this.customsPrice ? this.customsPrice * 10000000000 : undefined,
      productTypeId: this.productType.id,
    };
  }

  mapResponse(data) {
    data.customsPrice = data.customsPrice ? data.customsPrice / 10000000000 : 0;
    data.price = data.price ? data.price / 10000000000 : 0;
    return super.mapResponse(data);
  }
}

export default Product;
